
const { setPath } = require('spath');

const FieldError = require('../components/FieldError');

const goBack = require('../app/goBack');

const m = require('../mithril');

function CreateChannel (vnode) {
  const { tokens, context } = vnode.attrs;

  const formData = {
  };
  let errors = [];

  function handleInput (name) {
    return event => {
      formData[name] = event.target.value;
    };
  }

  async function handleSubmit (context, formData, event) {
    event.preventDefault();

    errors = { summary: [], fields: {} };
    m.redraw();

    const result = await context.api.post(`/communities/${tokens.communityName}/channels`, formData);

    if (result.body.status === 201) {
      goBack(`/communities/${tokens.communityName}/settings/channels`, 1)();
      return;
    }

    if (result.body.status === 422) {
      errors.fields = result.body.error.validation;
    }

    m.redraw();
  }

  return {

    view: () => {
      if (!context.session) {
        setPath('/login');
        return;
      }

      return (
        <div>
          <div class='heading'>
            <a tabindex='0' class='back-button' onclick={goBack(`/communities/${tokens.communityName}/settings/channels`, 1)}>‹ Back</a>
            <div class='title'>{tokens.communityName} › Settings › Channels › Create</div>
          </div>

          <main class='form-page'>
            <form onsubmit={handleSubmit.bind(null, context, formData)}>
              <b-form-field>
                <label>Channel Name</label>
                <FieldError errors={errors} errorKey='name' label='Confirm' customLanguage={{ INVALID_LENGTH: 'must be between 3 and 20 in length' }} />
                <input type='text' autocapitalize='off' oninput={handleInput('name')} required autofocus />
              </b-form-field>

              <b-form-field>
                <button class='button-primary'>Create</button>
              </b-form-field>
            </form>
          </main>
        </div>
      );
    }
  };
}

CreateChannel.activeMenu = 'community';

module.exports = CreateChannel;
