
const { setPath } = require('spath');

const FieldError = require('../components/FieldError');

const handleInput = require('../app/handleInput');
const goBack = require('../app/goBack');

const m = require('../mithril');

function CommunityLeave (vnode) {
  const { context, tokens } = vnode.attrs;

  const formData = {
    invitationType: 'private'
  };
  let errors;

  async function handleSubmit (context, formData, event) {
    event.preventDefault();

    errors = { summary: [], fields: {} };
    m.redraw();

    if (!formData.confirm) {
      errors.fields = {
        confirm: ['MUST_BE_TRUE']
      };
      m.redraw();
      return;
    }

    const result = await context.api.delete(`/communities/${tokens.communityName}/members/${context.session.user.username}`, formData);

    if (result.body.status === 200) {
      setPath('/');
      return;
    }

    if (result.body.status === 422) {
      errors.fields = result.body.error.validation;
    }

    m.redraw();
  }

  return {

    view: () => {
      if (!context.session) {
        setPath('/login');
        return;
      }

      return (
        <div>
          <div class='heading'>
            <a tabindex='0' class='back-button' onclick={goBack(`/communities/${tokens.communityName}`, 1)}>‹ Back</a>
            <div class='title'>{tokens.communityName} › Leave</div>
          </div>

          <main class='form-page'>
            <form onsubmit={handleSubmit.bind(null, context, formData)}>
              <p>
                <strong>Leave {tokens.communityName}?</strong>
              </p>

              <p>
                Are you sure you want to leave the {tokens.communityName} community?
              </p>

              <hr />

              <FieldError errors={errors} errorKey='confirm' label='Confirm' />
              <b-form-checkbox>
                <input id='confirm' oninput={handleInput(formData, 'confirm')} type='checkbox' />
                <label for='confirm'>Yes, I want to leave</label>
              </b-form-checkbox>

              <b-form-field>
                <button class='button-primary'>Leave</button>
              </b-form-field>
            </form>
          </main>
        </div>
      );
    }
  };
}

CommunityLeave.activeMenu = 'community';

module.exports = CommunityLeave;
