const cookie = require('cookie');
const m = require('../mithril');
const idbCache = window.idbCache = require('idb-keyval');

const notifications = require('./notifications');

function auth (context) {
  async function login (username, password) {
    const result = await context.api.post('/sessions', { username, password });

    if (result.body.status === 201) {
      idbCache.set('session', result.body);
      document.cookie = cookie.serialize('session.id', result.body.session.id, { maxAge: 3153600 });
      document.cookie = cookie.serialize('session.secret', result.body.session.secret, { maxAge: 3153600 });
      context.session = result.body;

      notifications.registerDevice(context);
    }

    await setStatus('online');

    return result;
  }

  async function register (data) {
    const result = await context.api.post('/users', data);

    if (result.body.status === 201) {
      await login(data.username, data.password);
    }

    return result;
  }

  async function logout () {
    await context.api.delete('/sessions/current');

    notifications.unregisterDevice(context);

    require('idb-keyval').clear();
    document.cookie = cookie.serialize('session.id', null, { maxAge: 0 });
    document.cookie = cookie.serialize('session.secret', null, { maxAge: 0 });

    context.session = null;
    m.redraw();
  }

  function setStatus (status) {
    return context.api.put('/sessions/current', { status });
  }

  return {
    login,
    register,
    logout,
    setStatus
  };
}

module.exports = auth;
