
const { setPath } = require('spath');

const Loading = require('../components/Loading');

const subscriptionToState = require('../app/subscriptionToState');
const m = require('../mithril');

function ContactsHome (vnode) {
  const { context } = vnode.attrs;

  const state = {
    contacts: []
  };

  function RenderMain () {
    if (!state.ready) {
      return <Loading />;
    }

    return (
      <main class='listview'>
        {state.contacts && state.contacts.map(contact => {
          return (
            <div onclick={() => setPath(`/contacts/${contact.username}/messages`)} key={contact.username} tabindex='0'>
              <div class='avatar'>
                <img src={require('../../images/boy-1.png')} />
              </div>
              <div class='listview-item-main'>
                <div>
                  <strong>{contact.username}</strong>
                </div>
              </div>
            </div>
          );
        })}

        <div class='list-item commands'>
          <a href='/create/contact'>Add new contact</a>
        </div>
      </main>
    );
  }

  return {

    oncreate: async () => {
      state.subscriptions = subscriptionToState(context, {
        state,
        sort: (a, b) => {
          return a.username >= b.username ? 1 : -1;
        },
        mappings: {
          contacts: '/contacts'
        }
      });
    },

    onremove: async () => {
      state.subscriptions.close();
    },

    view: (vnode) => {
      const { context } = vnode.attrs;

      if (!context.session) {
        setPath('/login');
        return;
      }

      return (
        <div>
          <div class='heading'>
            <div class='title'>Contacts</div>

            <b-dropdown tabindex='0'>
              <b-dropdown-button class='button'>
                +
              </b-dropdown-button>

              <b-dropdown-content>
                <ul>
                  <li><a onclick={() => setPath('/create/contact')}>Add new contact</a></li>
                </ul>
              </b-dropdown-content>
            </b-dropdown>

          </div>
          {RenderMain()}
        </div>
      );
    }
  };
}

ContactsHome.activeMenu = 'contacts';

module.exports = ContactsHome;
