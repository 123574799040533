
const { setPath } = require('spath');

const Loading = require('../components/Loading');
const goBack = require('../app/goBack');

const subscriptionToState = require('../app/subscriptionToState');

const m = require('../mithril');

function CommunityHome (vnode) {
  const { context, tokens } = vnode.attrs;

  const state = {
    channels: []
  };

  function RenderMain () {
    return {
      view: () => {
        if (!state.ready) {
          return <Loading />;
        }

        if (!state.community) {
          return (
            <main class='not-found-page'>
              <p>Community <strong>{tokens.communityName}</strong> not found</p>
              <a href='/'>Back to home</a>
            </main>
          );
        }

        return (
          <main class='listview'>
            {state.channels && state.channels.map(channel => {
              return (
                <div key={channel.name} tabindex='0' onclick={() => setPath(`/communities/${tokens.communityName}/channels/${channel.name}`)}>
                  <div class='listview-item-main'>
                    <strong>{channel.name}</strong>
                  </div>
                  <div>
                    {channel.unreadMessages > 0 && <span class='badge badge-red'>{channel.unreadMessages}</span>}
                  </div>
                </div>
              );
            })}

            <div tabindex='0' onclick={() => setPath(`/communities/${tokens.communityName}/members`)}>
              <div class='listview-item-main'>
                <strong>Members</strong>
              </div>
            </div>

            <div tabindex='0' onclick={() => setPath(`/communities/${tokens.communityName}/settings`)}>
              <div class='listview-item-main'>
                <strong>Settings</strong>
              </div>
            </div>

            <div tabindex='0' onclick={() => setPath(`/communities/${tokens.communityName}/leave`)}>
              <div class='listview-item-main'>
                <strong>Leave Community</strong>
              </div>
            </div>

          </main>
        );
      }
    };
  }

  return {

    oncreate: async () => {
      state.subscriptions = subscriptionToState(context, {
        state,
        sort: (a, b) => {
          return a.name >= b.name ? 1 : -1;
        },
        mappings: {
          channels: `/communities/${tokens.communityName}/channels`,
          community: `/communities/${tokens.communityName}`
        }
      });
    },

    onremove: async () => {
      state.subscriptions.close();
    },

    view: (vnode) => {
      const { context, tokens } = vnode.attrs;

      if (!context.session) {
        setPath('/login');
        return;
      }

      return (
        <div>
          <div class='heading'>
            <a tabindex='0' class='back-button' onclick={goBack('/', 1)}>‹ Back</a>
            <div class='title'>{tokens.communityName}</div>
          </div>
          <RenderMain communityName={tokens.communityName} />
        </div>
      );
    }
  };
}

CommunityHome.activeMenu = 'community';

module.exports = CommunityHome;
