
const { setPath } = require('spath');

const m = require('../mithril');

function LoginPage (vnode) {
  const { context } = vnode.attrs;

  const formData = {};
  let errors = [];

  const state = {};

  function handleInput (name) {
    return event => {
      formData[name] = event.target.value;
    };
  }

  async function handleSubmit (context, formData, event) {
    event.preventDefault();

    state.loading = true;
    errors = [];
    m.redraw();

    const result = await context.auth.login(formData.username, formData.password);

    if (result.body.status === 201) {
      setPath('/');
      return;
    }

    state.loading = false;
    errors.push('Incorrect login details');
    m.redraw();
  }

  return {
    view: () => {
      if (context.session) {
        setPath('/');
        return;
      }

      if (state.loading) {
        return (
          <div class='wrapper with-padding'>
            <b-auth-box>
              <img src={require('../../images/logo.svg')} />

              <div>
                <p>Welcome to Bumbana Collaboration.</p>
                <div class='loading'>
                  <img src={require('../../images/loading.svg')} />
                </div>
              </div>

              <div />

            </b-auth-box>
          </div>
        );
      }

      return (
        <div class='wrapper with-padding'>
          <b-auth-box>
            <img src={require('../../images/logo.svg')} />

            <div>
              <p>Welcome to Bumbana Collaboration.</p>
              <p>Login using the form below.</p>
            </div>

            {errors.map((error, index) =>
              <b-form-error key={index}>
                {error}
              </b-form-error>
            )}

            <form onsubmit={handleSubmit.bind(null, context, formData)}>
              <b-form-field>
                <label>Username</label>
                <input type='text' value={formData.username} autocapitalize='off' oninput={handleInput('username')} required autofocus />
              </b-form-field>

              <b-form-field>
                <label>Password</label>
                <input type='password' value={formData.password} oninput={handleInput('password')} required />
              </b-form-field>

              <b-form-field>
                <button class='button-primary'>Login</button>
              </b-form-field>
            </form>

            <div>
              If you are not registered with us, <a href='/join'>Sign up</a>
            </div>

          </b-auth-box>
        </div>
      );
    }
  };
}

LoginPage.layout = 'none';

module.exports = LoginPage;
