function waitFor (fn, timeout) {
  const startNow = Date.now();
  return new Promise(resolve => {
    const timer = setInterval(() => {
      if (fn() || Date.now() - startNow > timeout) {
        clearInterval(timer);
        resolve();
      } else {
        console.log('not connected');
      }
    }, 500);
  });
}

module.exports = waitFor;
