
const { setPath } = require('spath');

const Loading = require('../components/Loading');

const subscriptionToState = require('../app/subscriptionToState');
const goBack = require('../app/goBack');
const m = require('../mithril');

function CommunityManageChannels (vnode) {
  const { context, tokens } = vnode.attrs;

  const state = {
    channels: []
  };

  function RenderMain (communityName) {
    if (!state.ready) {
      return <Loading />;
    }

    if (!state.community) {
      return (
        <main class='not-found-page'>
          <p>Community <strong>{communityName}</strong> not found</p>
          <a href='/'>Back to home</a>
        </main>
      );
    }

    return (
      <main class='listview'>
        {state.channels && state.channels.map(channel => {
          return (
            <div key={channel.name} tabindex='0'>
              <div class='listview-item-main'>
                <div>
                  <strong>{channel.name}</strong>
                </div>
              </div>
            </div>
          );
        })}

        <div class='list-item commands'>
          <a href={`/communities/${communityName}/settings/channels/create`}>Create new channel</a>
        </div>
      </main>
    );
  }

  return {

    oncreate: async () => {
      state.subscriptions = subscriptionToState(context, {
        state,
        sort: (a, b) => {
          return a.name >= b.name ? 1 : -1;
        },
        mappings: {
          channels: `/communities/${tokens.communityName}/channels`,
          community: `/communities/${tokens.communityName}`
        }
      });
    },

    onremove: async () => {
      state.subscriptions.close();
    },

    view: (vnode) => {
      const { context, tokens } = vnode.attrs;

      if (!context.session) {
        setPath('/login');
        return;
      }

      return (
        <div>
          <div class='heading'>
            <a tabindex='0' class='back-button' onclick={goBack(`/communities/${tokens.communityName}/settings`, 1)}>‹ Back</a>
            <div class='title'>{tokens.communityName} › Settings › Channels</div>

            <b-dropdown tabindex='0'>
              <b-dropdown-button class='button'>
                +
              </b-dropdown-button>

              <b-dropdown-content>
                <ul>
                  <li><a onclick={() => setPath(`/communities/${tokens.communityName}/settings/channels/create`)}>Create new channel</a></li>
                </ul>
              </b-dropdown-content>
            </b-dropdown>

          </div>
          {RenderMain(tokens.communityName)}
        </div>
      );
    }
  };
}

CommunityManageChannels.activeMenu = 'commuinity';

module.exports = CommunityManageChannels;
