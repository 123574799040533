const idbCache = window.idbCache = require('idb-keyval');

const m = require('../mithril');
const subscriptionStateSyncer = require('./subscriptionStateSyncer');
const waitFor = require('./waitFor');

function subscriptionToState (context, { state, sort, autoRedraw = true, mappings, customCacheLogic, onMessage }) {
  const subscriptions = [];

  let stopCalled = false;
  let numberReady = 0;

  function oneReady () {
    numberReady = numberReady + 1;
    if (numberReady >= Object.keys(mappings).length) {
      state.ready = true;
      m.redraw();
    }
  }

  const promises = Object
    .keys(mappings)
    .map(async key => {
      const path = mappings[key];

      const cachedResult = await idbCache.get(path);
      if (cachedResult) {
        state[key] = cachedResult;
        oneReady();
      }

      if (!context.connected) {
        await waitFor(() => stopCalled || context.connected, 20000);
      }

      const results = await context.api.get(path);
      const resourceType = results.body[key] && Array.isArray(results.body[key]) ? 'array' : 'object';

      if ([200, 201].includes(results.body.status)) {
        if (resourceType === 'array') {
          sort && results.body[key].sort(sort);
        }

        idbCache.set(path, results.body[key]);
      }
      state[key] = results.body[key];

      if (!cachedResult) {
        oneReady();
      }

      let subscription;
      if (resourceType === 'array') {
        subscription = await context.api.subscribe(path, {},
          (event) => {
            subscriptionStateSyncer.syncArray(state, key)(event);

            if (sort) {
              state[key].sort(sort);
            }

            customCacheLogic
              ? customCacheLogic(idbCache, path, state, key)
              : idbCache.set(path, state[key]);

            autoRedraw && m.redraw();
            onMessage && onMessage(event);
          }
        );

        sort && state[key].sort(sort);
      }

      if (resourceType === 'object') {
        subscription = await context.api.subscribe(path, {},
          (event) => {
            subscriptionStateSyncer.syncObject(state, key)(event);
            idbCache.set(path, state[key]);
            autoRedraw && m.redraw();
            onMessage && onMessage(event);
          }
        );
      }

      m.redraw();

      subscriptions.push(subscription);
    });

  function close () {
    stopCalled = true;

    if (!state.ready) {
      return;
    }

    subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  Promise.all(promises).then(() => {
    if (stopCalled) {
      close();
      return;
    }
    state.ready = true;
    m.redraw();
  });

  return {
    close
  };
}

module.exports = subscriptionToState;
