/** @jsx m */
const m = require('../mithril');

const { setPath } = require('spath');

function Footer () {
  return {
    view: (vnode) => {
      const { active } = vnode.attrs;

      return (
        <footer>
          <a class={active === 'community' ? 'active' : null} onclick={() => setPath('/')}>
            <img src={require('../../images/menu-app.png')} />
            <span>Community</span>
          </a>
          <a class={active === 'contacts' ? 'active' : null} onclick={() => setPath('/contacts')}>
            <img src={require('../../images/call-participants.png')} />
            <span>Contacts</span>
          </a>
          <a class={active === 'calls' ? 'active' : null} onclick={() => setPath('/calls')}>
            <img src={require('../../images/call-audio.png')} />
            <span>Calls</span>
          </a>
          <a class={active === 'settings' ? 'active' : null} onclick={() => setPath('/settings')}>
            <img src={require('../../images/menu-message.png')} />
            <span>Settings</span>
          </a>
        </footer>
      );
    }
  };
}

module.exports = Footer;
