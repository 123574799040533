
const { setPath } = require('spath');

const m = require('../mithril');

const goBack = require('../app/goBack');

function CommunitySettings (vnode) {
  const { context, tokens } = vnode.attrs;

  function RenderMain (communityName) {
    return (
      <main class='listview'>
        <div tabindex='0' onclick={() => setPath(`/communities/${communityName}/settings/channels`)}>
          <div class='listview-item-main'>
            <strong>Manage Channels</strong>
          </div>
        </div>
      </main>
    );
  }

  return {

    view: () => {
      if (!context.session) {
        setPath('/login');
        return;
      }

      return (
        <div>
          <div class='heading'>
            <a tabindex='0' class='back-button' onclick={goBack(`/communities/${tokens.communityName}`, 1)}>‹ Back</a>
            <div class='title'>{tokens.communityName} › Settings</div>
          </div>
          {RenderMain(tokens.communityName)}
        </div>
      );
    }
  };
}

CommunitySettings.activeMenu = 'community';

module.exports = CommunitySettings;
