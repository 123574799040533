const { version } = require('../../../package.json');
const { setPath } = require('spath');
const format = require('date-fns/format');

const m = require('../mithril');

function SettingsHome () {
  const state = {};

  async function handleClearCache () {
    state.clearingCache = true;
    m.redraw();

    require('idb-keyval').clear();

    await navigator.serviceWorker.getRegistrations().then(function (registrations) {
      return Promise.all(registrations.map(registration => {
        return registration.unregister();
      }));
    });

    setInterval(() => {
      window.location.reload();
    }, 1000);
  }

  return {

    view: (vnode) => {
      const { context } = vnode.attrs;

      if (!context.session) {
        setPath('/login');
        return;
      }

      return (
        <div>
          <div class='heading'>
            <div class='title'>Settings</div>
          </div>
          <main class='listview'>
            <div class='listview-item-main' onclick={() => setPath('/settings/changelog')}>
              <a><strong>View changelog</strong></a>
            </div>
            <div class='listview-item-main' onclick={handleClearCache} disabled={state.clearingCache}>
              {state.clearingCache && <img src={require('../../images/loading-20x20.gif')} />}
              <a><strong>Clear cache and reload</strong></a>
            </div>
            <div class='listview-item-main'>
              <a><strong>Version:</strong> {version}</a>
            </div>
            <div class='listview-item-main'>
              <a><strong>Build Date:</strong> {format(process.env.BUILD_DATE, 'yyyy-MM-dd HH:mm:ss')}</a>
            </div>
          </main>
        </div>
      );
    }
  };
}

SettingsHome.activeMenu = 'settings';

module.exports = SettingsHome;
