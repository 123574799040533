function base64ToUint8Array (base64) {
  const padding = '='.repeat((4 - (base64.length % 4)) % 4);
  const b64 = (base64 + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(b64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

async function registerDevice (context) {
  const registration = await navigator.serviceWorker.register('/serviceWorker.js');

  await navigator.serviceWorker.ready;

  const existingSubscription = await registration.pushManager.getSubscription();

  if (existingSubscription) {
    return;
  }

  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey: base64ToUint8Array(context.config.vapidKey)
  };

  const pushSubscription = await registration.pushManager.subscribe(subscribeOptions);

  const subscription = JSON.parse(JSON.stringify(pushSubscription));

  console.log('SUBSCRIBE', subscription);
  context.api.post('/devices', {
    endpoint: subscription.endpoint,
    p256dh: subscription.keys.p256dh,
    auth: subscription.keys.auth
  });
}

function unregisterDevice (context) {
  // context.api.delete('/devices/' + token, {
  //   token
  // });
}

module.exports = {
  registerDevice,
  unregisterDevice
};
