
const { setPath } = require('spath');

const FieldError = require('../components/FieldError');
const handleInput = require('../app/handleInput');

const m = require('../mithril');

function JoinPage (vnode) {
  const { context } = vnode.attrs;

  const formData = {
    agreeToTerms: false,
    agreeToMailingList: false
  };
  const state = {};
  let errors;

  async function handleSubmit (context, formData, event) {
    event.preventDefault();

    state.loading = true;
    errors = { summary: [], fields: {} };
    m.redraw();

    const result = await context.auth.register(formData);

    if (result.body.status === 201) {
      setPath('/');
      return;
    }

    if (result.body.status === 422) {
      errors.fields = result.body.error.validation;
    }

    state.loading = false;
    errors.summary.push('Incorrect register details');
    m.redraw();
  }

  return {
    view: () => {
      if (context.session) {
        setPath('/');
        return;
      }

      return (
        <div class='wrapper with-padding'>
          <b-auth-box>
            <img src={require('../../images/logo.svg')} />

            <div>
              <p>Welcome to Bumbana Collaboration.</p>
              <p>Create your free account below.</p>
            </div>

            <form onsubmit={handleSubmit.bind(null, context, formData)}>
              <b-form-field>
                <label>Username</label>
                <FieldError errors={errors} errorKey='username' />
                <input type='text' value={formData.username} autocapitalize='off' oninput={handleInput(formData, 'username')} required autofocus />
              </b-form-field>

              <b-form-field>
                <label>Email</label>
                <FieldError errors={errors} errorKey='email' />
                <input type='email' value={formData.email} oninput={handleInput(formData, 'email')} required />
              </b-form-field>

              <b-form-field>
                <label>Telephone Number</label>
                <FieldError errors={errors} errorKey='mobile' />
                <input type='tel' value={formData.mobile} oninput={handleInput(formData, 'mobile')} required />
              </b-form-field>

              <b-form-field>
                <label>Password</label>
                <FieldError errors={errors} errorKey='password' />
                <input type='password' value={formData.password} oninput={handleInput(formData, 'password')} />
              </b-form-field>

              <b-form-field>
                <label>Confirm Password</label>
                <FieldError errors={errors} errorKey='confirm' label='Password Confirmation' />
                <input type='password' value={formData.passwordConfirmation} oninput={handleInput(formData, 'passwordConfirmation')} />
              </b-form-field>

              <FieldError errors={errors} errorKey='agreeToTerms' label='Agree to terms' />
              <b-form-checkbox>
                <input id='terms' oninput={handleInput(formData, 'agreeToTerms')} type='checkbox' />
                <label for='terms'>I Agree Bumbana Terms of Use and Privacy Policy</label>
              </b-form-checkbox>

              <FieldError errors={errors} errorKey='agreeToMailingList' label='Agree to mailing list' />
              <b-form-checkbox>
                <input id='mailingList' oninput={handleInput(formData, 'agreeToMailingList')} type='checkbox' />
                <label for='mailingList'>Sign me up to the mailing list</label>
              </b-form-checkbox>

              <b-form-field>
                <button class='button-primary' disabled={state.loading}>Create Account</button>
              </b-form-field>
            </form>

            <div>
              Existing user? <a href='/login'>login</a>
            </div>

          </b-auth-box>
        </div>
      );
    }
  };
}

JoinPage.layout = 'none';

module.exports = JoinPage;
