const m = require('../mithril');

const notifications = require('./notifications');

const RestocketClient = require('restocket/client').default;
const cookie = require('cookie');

const idbCache = window.idbCache = require('idb-keyval');

function clearSession (context) {
  document.cookie = cookie.serialize('session.id', null, { maxAge: 0 });
  document.cookie = cookie.serialize('session.secret', null, { maxAge: 0 });
  idbCache.clear();
  context.session = null;
  context.connected = true;
  context.state.loading = false;
  m.redraw();
}

async function createApp (context) {
  window.context = context;

  context.state = {
    loading: true
  };

  await idbCache.get('session').then(cachedSession => {
    if (cachedSession) {
      context.state.loading = false;
      context.session = cachedSession;
    }
  });

  let refreshOnConnection = false;

  if (context.config.enableServiceWorker) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/serviceWorker.js', {})
        .then(() => {
          console.log('Service Worker - Registration succeeded');
        }).catch((error) => {
          console.log('Service Worker - Registration failed with ' + error);
        });
    }
  }

  context.api = new RestocketClient({
    host: context.config.apiServerUrl
  });

  context.api.socket.on('disconnect', async () => {
    refreshOnConnection = true;
    context.connected = false;
  });

  context.api.socket.on('connect', async () => {
    if (refreshOnConnection) {
      window.location.reload();
      return;
    }

    const cookies = cookie.parse(document.cookie);

    if (cookies['session.id'] && cookies['session.secret']) {
      const session = await context.api.put('/sessions/current', {
        sessionId: cookies['session.id'],
        sessionSecret: cookies['session.secret']
      });

      if (session.body.status === 200) {
        notifications.registerDevice(context);
        context.session = session.body;
        idbCache.set('session', session.body);
        context.auth.setStatus('online');
      } else {
        const retry = confirm('Could you log you in.\n\nClick Ok to try again, or cancel to logout.')
        if (retry) {
          window.location.reload();
        } else {
          console.log('cleaning session');
          clearSession(context);
        }
      }
    }

    context.connected = true;
    context.state.loading = false;
    m.redraw();
  });

  context.auth = require('./auth')(context);

  return context;
}

module.exports = createApp;
