function handleInput (formData, name) {
  return event => {
    if (event.target.type === 'checkbox') {
      formData[name] = event.target.checked;
    } else {
      formData[name] = event.target.value;
    }
  };
}

module.exports = handleInput;
