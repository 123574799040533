
const { setPath } = require('spath');

const goBack = require('../app/goBack');

const subscriptionToState = require('../app/subscriptionToState');
const debounce = require('debounce-promise');
const m = require('../mithril');

function ContactInvite (vnode) {
  const { context } = vnode.attrs;

  const state = {
  };

  const formData = {
    name: ''
  };

  const refreshResults = debounce(async function () {
    const users = await context.api.get('/users?name=' + formData.name);
    state.searchResults = users.body.users;

    m.redraw();
  }, 250);

  function handleInput (event) {
    formData.name = event.target.value;
    refreshResults();
  }

  async function handleAdd (context, username, event) {
    event.preventDefault();
    event.stopPropagation();

    const result = await context.api.post('/contacts', {
      username
    });

    if (result.body.status === 201) {
      refreshResults();
      return;
    }

    m.redraw();
  }

  function removeSelf (user) {
    return user.username !== context.session.user.username;
  }

  return {

    oncreate: async () => {
      state.subscriptions = subscriptionToState(context, {
        state,
        mappings: {
          contacts: '/contacts'
        }
      });
      refreshResults();
    },

    onremove: async () => {
      state.subscriptions.close();
    },

    view: (vnode) => {
      const { context } = vnode.attrs;

      if (!context.session) {
        setPath('/login');
        return;
      }

      return (
        <div>
          <div class='heading'>
            <a tabindex='0' class='back-button' onclick={goBack('/', 1)}>‹ Back</a>
            <div class='title'>Contacts › Add</div>
          </div>

          <main class='listview'>
            <div class='search-field'>
              <input autofocus oninput={handleInput} placeholder='Search by username' />
            </div>

            {state.searchResults && state.searchResults.length === 0 && <div><div>No users found</div></div>}

            {state.searchResults && state.searchResults.filter(removeSelf).map(user => {
              const connected = state.contacts && state.contacts.find(contact => user.username === contact.username);

              return (
                <div onclick={() => connected && setPath('/users/' + user.name)} tabindex='0' key={user.username}>
                  <div class='avatar'>
                    <img src={require('../../images/boy-1.png')} />
                  </div>
                  <div class='listview-item-main'>
                    <strong>{user.username}</strong>
                  </div>
                  <div>
                    {!connected && <button class='large-button' onclick={handleAdd.bind(null, context, user.username)}>Add</button>}
                    {connected && <button class='large-button' disabled>Connected</button>}
                  </div>
                </div>
              );
            })}
          </main>
        </div>
      );
    }
  };
}

ContactInvite.activeMenu = 'contacts';

module.exports = ContactInvite;
