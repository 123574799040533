/** @jsx m */
const m = require('../mithril');

function capitalize (string) {
  return string[0].toUpperCase() + string.slice(1);
}
const defaultLanguage = {
  MUST_BE_TRUE: 'must be checked',
  SHOULD_SAME_AS_PASSWORD: 'must match password',
  TAKEN: 'already exists',
  INVALID: 'is invalid',
  EXISTS: 'already exists',
  REQUIRED: 'is required',
  MUST_BE_ALPHANUMERIC: 'can only contain letters and numbers'
};

function FieldError () {
  return {
    view: (vnode) => {
      const { errors, errorKey, label, customLanguage } = vnode.attrs;

      const language = {
        ...defaultLanguage,
        ...customLanguage
      };

      if (errors && errors.fields && errors.fields[errorKey] && errors.fields[errorKey].length > 0) {
        return (
          <b-form-error>
            {errors.fields[errorKey].map((error, errorIndex) => (
              <li key={errorIndex}>
                {label || capitalize(errorKey)} {language[error] ? language[error] : error}
              </li>
            ))}
          </b-form-error>
        );
      } else {
        return null;
      }
    }
  };
}

module.exports = FieldError;
