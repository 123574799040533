
const { setPath } = require('spath');

const ChatList = require('../components/ChatList');
const goBack = require('../app/goBack');

const m = require('../mithril');

const handleChatAutoScroll = require('../app/handleChatAutoScroll');
const subscriptionToState = require('../app/subscriptionToState');

function ChannelHome (vnode) {
  const { context, tokens } = vnode.attrs;

  const state = {
    autoScrollToLast: true
  };

  async function onGrabMoreMessages (cb) {
    const createdBefore = state.messages[0].createdTime;
    const result = await context.api.get(`/communities/${tokens.communityName}/channels/${tokens.channelName}/messages?createdBefore=${createdBefore}`);

    state.messages = result.body.messages.concat(state.messages);

    cb && cb();
  }

  function onCreateMessage (formData) {
    return context.api.post(`/communities/${tokens.communityName}/channels/${tokens.channelName}/messages`, formData);
  }

  return {

    oncreate: async () => {
      context.api.put(`/communities/${tokens.communityName}/channels/${tokens.channelName}`, {
        unreadMessages: 0
      });

      state.subscriptions = subscriptionToState(context, {
        state,
        mappings: {
          messages: `/communities/${tokens.communityName}/channels/${tokens.channelName}/messages`
        },
        autoRedraw: false,
        onMessage: handleChatAutoScroll.bind(null, state),
        customCacheLogic: function (idbCache, path, state, key) {
          idbCache.set(path, state[key].slice(-100));
        }
      });
      window.state = state;
    },

    onremove: async () => {
      state.subscriptions.close();
    },

    view: () => {
      if (!context.session) {
        setPath('/login');
        return;
      }

      return (
        <div>
          <div class='heading'>
            <a tabindex='0' class='back-button' onclick={goBack(`/communities/${tokens.communityName}`, 1)}>‹ Back</a>
            <div class='title'>{tokens.communityName} › {tokens.channelName}</div>
          </div>
          <ChatList {...{ context, state, tokens, onCreateMessage, onGrabMoreMessages }} />
        </div>
      );
    }
  };
}

ChannelHome.activeMenu = 'community';
ChannelHome.hideMenu = true;

module.exports = ChannelHome;
