/** @jsx m */

const format = require('date-fns/format');
const isToday = require('date-fns/isToday');

const classnames = require('classnames');
const removeAfterAnimations = require('../app/removeAfterAnimations');
const Loading = require('../components/Loading');
const autosize = require('autosize');
const m = require('../mithril');

function ChatList (vnode) {
  const { state, tokens, context, onCreateMessage, onGrabMoreMessages } = vnode.attrs;

  const sendingMessages = [];

  function ChatFooter (vnode) {
    const formData = {};

    function handleInput (name) {
      return event => {
        const emptyBefore = !formData[name];
        formData[name] = event.target.value;

        if (emptyBefore || !formData[name]) {
          m.redraw();
        }
      };
    }

    async function handleSend () {
      document.querySelector('textarea').focus();

      if (!formData.message) {
        return;
      }

      const pendingMessage = {
        sending: true,
        username: context.session.user.username,
        message: formData.message,
        createdTime: new Date()
      };

      sendingMessages.push(pendingMessage);

      onCreateMessage(formData)
        .then(result => {
          if (result.body.status !== 200) {
            window.alert(result.body.error.friendly);
            return;
          }
          const index = sendingMessages.indexOf(pendingMessage);
          sendingMessages.splice(index, 1);
          m.redraw();
        });

      formData.message = '';
      m.redraw();

      const textareaElement = vnode.dom.querySelector('textarea');
      autosize.update(textareaElement);

      document.querySelector('textarea').focus();
      document.querySelector('.chat-list').scrollTop = 0;
    }

    function handleTextInputClick () {
      document.querySelector('.chat-list').scrollTop = 0;
    }

    const handleMessageKeyPress = (event) => {
      if (event.key !== 'Enter') {
        return;
      }

      if (event.shiftKey || event.ctrlKey || event.altKey) {
        return;
      }

      event.preventDefault();
      handleSend();
    };

    return {
      oncreate: (vnode) => {
        const textareaElement = vnode.dom.querySelector('textarea');
        autosize(textareaElement);
      },
      view: () => {
        return (
          <form class='chat-footer'>
            <div class='text-input'>
              <textarea
                required
                onclick={handleTextInputClick}
                oninput={handleInput('message')}
                onkeypress={handleMessageKeyPress}
                onfocus={handleTextAreaFocus}
                rows='1'
                placeholder='Send message'
                value={formData.message || ''}
              />
            </div>
            <div class='buttons' onclick={handleSend}>
              <button disabled={!formData.message} onclick={event => event.preventDefault()} class='send-button' />
            </div>
          </form>
        );
      }
    };
  }

  async function grabMoreMessages () {
    if (state.grabbing) {
      return;
    }

    if (!state.messages) {
      return;
    }

    state.grabbing = true;

    onGrabMoreMessages(function () {
      const oldScrollTop = document.querySelector('.chat-list').scrollTop;

      state.grabbing = false;
      m.redraw();

      document.querySelector('.chat-list').scrollTop = oldScrollTop;
    });
  }

  function handleTextAreaFocus () {
    setTimeout(() => {
      const chatWindow = document.querySelector('.chat-list');
      if (chatWindow) {
        chatWindow.scrollTop = chatWindow.scrollHeight;
      }
    }, 200);
  }

  function handleScroll (event) {
    state.autoScrollToLast = event.srcElement.scrollTop > -100;

    const messagesHeight = event.srcElement.scrollHeight;
    const messagesTop = (event.srcElement.scrollTop - event.srcElement.offsetHeight) * -1;

    if (messagesHeight - messagesTop < 300) {
      grabMoreMessages();
    }
  }

  return {
    onbeforeremove: removeAfterAnimations,
    view: () => {
      if (!state.ready) {
        return <Loading />;
      }

      let lastMessageDate;
      return m.fragment(
        <main class='chat-list' onscroll={handleScroll}>
          <div class='chat-content'>
            {state.messages && state.messages.concat(sendingMessages).map(message => {
              const ownMessage = message.username === context.session.user.username;
              const messageCreatedDate = new Date(message.createdTime);
              const messageDate = isToday(messageCreatedDate) ? 'Today' : format(messageCreatedDate, 'EEE, do MMMM yyyy');

              const dateGroup = (lastMessageDate !== messageDate || !lastMessageDate) &&
                <div class='date-group'>{messageDate}</div>;

              lastMessageDate = messageDate;

              return m.fragment(
                dateGroup,
                <div class={classnames({
                  'message-self': ownMessage,
                  'message-sending': message.sending
                })}
                >
                  <div class='profile-picture'>
                    <img src={require('../../images/boy-1.png')} />
                  </div>

                  <div>
                    {!ownMessage && <strong>{message.username}</strong>}
                    <div>
                      <pre>{message.message}</pre>
                      <div class='message-time'>
                        {format(new Date(message.createdTime), 'HH:mm:ss')}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </main>,
        <ChatFooter tokens={tokens} context={context} />
      );
    }
  };
}

module.exports = ChatList;
