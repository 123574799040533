
const { setPath } = require('spath');
const Loading = require('../components/Loading');

const colorhash = require('../../../modules/colorhash');
const subscriptionToState = require('../app/subscriptionToState');

const m = require('../mithril');

const colors = [
  'hsl(240deg 125% 30%)',
  'hsl(220deg 125% 30%)',
  'hsl(200deg 125% 30%)',
  'hsl(180deg 125% 30%)',
  'hsl(160deg 125% 30%)',
  'hsl(140deg 125% 30%)',
  'hsl(120deg 125% 30%)'
];

function HomePage (vnode) {
  const { context } = vnode.attrs;

  const state = {
    communities: []
  };

  function RenderMain () {
    return {
      view: () => {
        if (!state.ready) {
          return <Loading />;
        }

        return (
          <main class='listview'>
            {state.communities.map(community => {
              return (
                <div onclick={() => setPath('/communities/' + community.name)} tabindex='0' key={community.name}>
                  <div>
                    <div
                      class='wordatar' style={{
                        backgroundColor: colorhash(colors, community.name)
                      }}
                    >
                      <span>{community.name[0]}</span>
                    </div>
                  </div>
                  <div class='listview-item-main'>
                    <strong>{community.name}</strong>
                    <span>{community.memberCount === 1 ? '1 member' : `${community.memberCount} members`}</span>
                  </div>
                </div>
              );
            })}

            <div class='list-item commands'>
              <a href='/search/communities'>Search for other communities</a>
            </div>

            <div class='list-item commands'>
              <a href='/create/community'>Create your own community</a>
            </div>
          </main>
        );
      }
    };
  }

  return {

    oncreate: async () => {
      state.subscriptions = subscriptionToState(context, {
        state,
        sort: (a, b) => {
          return a.name >= b.name ? 1 : -1;
        },
        mappings: {
          communities: '/communities?joined=true'
        }
      });
    },

    onremove: async () => {
      state.subscriptions.close();
    },

    view: (vnode) => {
      const { context } = vnode.attrs;

      if (!context.session) {
        setPath('/login');
        return;
      }

      return (
        <div>
          <div class='heading'>
            <div class='title'>Communities</div>

            <b-dropdown tabindex='0'>
              <b-dropdown-button class='button'>
                +
              </b-dropdown-button>

              <b-dropdown-content>
                <ul>
                  <li><a onclick={() => setPath('/search/communities')}>Search for other communities</a></li>
                  <li><a onclick={() => setPath('/create/community')}>Create new community</a></li>
                </ul>
              </b-dropdown-content>
            </b-dropdown>
          </div>
          <RenderMain />
        </div>
      );
    }
  };
}

HomePage.activeMenu = 'community';

module.exports = HomePage;
