require('location-change-event');

const routemeup = require('routemeup');
const mithril = require('./mithril');
const pushStateAnchors = require('spath/pushStateAnchors');
const createApp = require('./app');

const DefaultLayout = require('./layouts/DefaultLayout');

const m = require('./mithril');
const config = require('../../config.js');

const routes = {
  '/': () => require('./pages/HomePage'),
  '/settings': () => require('./pages/SettingsHome'),
  '/settings/changelog': () => require('./pages/SettingsChangelogPage'),
  '/calls': () => require('./pages/CallsHome'),
  '/contacts/:username/messages': () => require('./pages/ContactMessagesHome'),
  '/contacts': () => require('./pages/ContactsHome'),
  '/create/contact': () => require('./pages/ContactCreate'),
  '/search/communities': () => require('./pages/SearchCommunities'),
  '/create/community': () => require('./pages/CreateCommunity'),
  '/invite/communities/:communityName/members': () => require('./pages/CommunityInviteMembers'),
  '/communities/:communityName/channels/:channelName': () => require('./pages/ChannelHome'),
  '/communities/:communityName/settings/channels/create': () => require('./pages/CreateChannel'),
  '/communities/:communityName/settings/channels': () => require('./pages/CommunityManageChannels'),
  '/communities/:communityName/settings': () => require('./pages/CommunitySettings'),
  '/communities/:communityName/members': () => require('./pages/CommunityMembers'),
  '/communities/:communityName/leave': () => require('./pages/CommunityLeave'),
  '/communities/:communityName': () => require('./pages/CommunityHome'),
  '/login': () => require('./pages/LoginPage'),
  '/join': () => require('./pages/JoinPage')
};

function hijackNavigation () {
  document.addEventListener('click', pushStateAnchors());
  window.addEventListener('locationchange', () => {
    window.requestAnimationFrame(() => mithril.redraw(true));
  });
}

visualViewport && visualViewport.addEventListener('resize', function () {
  // This entire function is ridiculous
  // If removed, on first login, the footer is pushed too far off the screen
  // The visualViewport.height (when debugged) is incorrect
  // Changing anything, then fixes the height
  // It's most likely a browser bug.
  const timer = setInterval(() => {
    if (visualViewport.height < (screen.availHeight - (screen.availHeight * 0.10))) {
      document.body.classList.add('lessThanViewportHeight');
    } else {
      document.body.classList.remove('lessThanViewportHeight');
    }

    const element = document.body.querySelector('.wrapper');
    const currentHeight = parseInt(element.style.height) || 0;
    const requiredHeight = parseInt(window.visualViewport.height);
    if (currentHeight !== requiredHeight) {
      element.style.height = window.visualViewport.height + 'px';
      element.scrollTop = 0;
    }
  }, 100);

  setTimeout(function () {
    clearTimeout(timer);
  }, 2500);
});

document.addEventListener('DOMContentLoaded', async function () {
  const context = await createApp({ config });
  hijackNavigation();

  setTimeout(() => {
    document.body.classList.remove('first-load');
  }, 300);

  function render (isNavigating) {
    if (!context.session && !context.connected) {
      mithril.render(document.body, m(require('./pages/NotConnected')));
      return;
    }

    if (context.state.loading) {
      return null;
    }

    const route = routemeup(routes, { url: window.location.pathname });
    const controller = (route ? route.controller() : require('./pages/NotFoundPage'));
    const ui = mithril(controller, { context, tokens: route && route.tokens });

    if (controller.layout === 'none') {
      mithril.render(document.body, ui);
      return;
    }

    mithril.render(document.body, m(DefaultLayout, {
      isNavigating,
      active: controller.activeMenu,
      hideMenu: controller.hideMenu,
      context
    }, ui));
  }

  window.context = context;
  window.mithril = mithril;
  mithril.redraw = render;
  context.redraw = render;

  render();
});
