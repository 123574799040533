const m = require('../mithril');

function handleChatAutoScroll (state, data) {
  const chatListElement = document.querySelector('.chat-list');
  const oldScrollHeight = chatListElement.scrollHeight;
  const oldScrollTop = document.querySelector('.chat-list').scrollTop;

  m.redraw();

  if (data.method !== 'POST') {
    return;
  }

  if (state.autoScrollToLast) {
    chatListElement.scrollTop = 0;
  } else {
    const newScrollHeight = chatListElement.scrollHeight;
    const differenceInScrollHeight = newScrollHeight - oldScrollHeight;
    chatListElement.scrollTop = oldScrollTop - differenceInScrollHeight;
  }
}

module.exports = handleChatAutoScroll;
