
const m = require('../mithril');

function CallsHome () {
  return {

    view: () => {
      return (
        <div>
          <main class='not-found-page'>
            <p>COMING SOON!</p>
            <p>During the beta phase voice and video calling is not supported</p>
          </main>
        </div>
      );
    }
  };
}

module.exports = CallsHome;
