
const { setPath } = require('spath');

const Loading = require('../components/Loading');
const goBack = require('../app/goBack');

const subscriptionToState = require('../app/subscriptionToState');
const m = require('../mithril');

function CommunityMembers (vnode) {
  const { context, tokens } = vnode.attrs;

  const state = {
    channels: []
  };

  function handleKick (context, communityName, username, event) {
    event.preventDefault();
    event.stopPropagation();

    context.api.delete(`/communities/${communityName}/members/${username}`);
  }

  function RenderMain (communityName) {
    if (!state.ready) {
      return <Loading />;
    }

    if (!state.community) {
      return (
        <main class='not-found-page'>
          <p>Community <strong>{communityName}</strong> not found</p>
          <a href='/'>Back to home</a>
        </main>
      );
    }

    return (
      <main class='listview'>
        {state.members && state.members.map(member => {
          return (
            <div key={member.username} tabindex='0' onclick={() => setPath(`/communities/${communityName}/members/${member.username}`)}>
              <div class='avatar'>
                <img src={require('../../images/boy-1.png')} />
              </div>
              <div class='listview-item-main'>
                <strong>{member.username}</strong>
              </div>
              <div>
                {member.username !== context.session.user.username &&
                  <button class='large-button' onclick={handleKick.bind(null, context, tokens.communityName, member.username)}>Kick</button>}
              </div>
            </div>
          );
        })}

        <div class='list-item commands'>
          <a href={`/invite/communities/${communityName}/members`}>Invite new member</a>
        </div>
      </main>
    );
  }

  return {

    oncreate: async () => {
      state.subscriptions = subscriptionToState(context, {
        state,
        mappings: {
          members: `/communities/${tokens.communityName}/members`,
          community: `/communities/${tokens.communityName}`
        }
      });
      m.redraw();
    },

    onremove: async () => {
      state.subscriptions.close();
    },

    view: (vnode) => {
      const { context, tokens } = vnode.attrs;

      if (!context.session) {
        setPath('/login');
        return;
      }

      return (
        <div>
          <div class='heading'>
            <a tabindex='0' class='back-button' onclick={goBack(`/communities/${tokens.communityName}`)}>‹ Back</a>
            <div class='title'>{tokens.communityName} › Members</div>
          </div>
          {RenderMain(tokens.communityName)}
        </div>
      );
    }
  };
}

CommunityMembers.activeMenu = 'community';

module.exports = CommunityMembers;
