
const { setPath } = require('spath');

const m = require('../mithril');

function NotConnected (vnode) {
  const { context } = vnode.attrs;

  const formData = {};
  let errors = [];

  const state = {};

  function handleInput (name) {
    return event => {
      formData[name] = event.target.value;
    };
  }

  async function handleSubmit (context, formData, event) {
    event.preventDefault();

    state.loading = true;
    errors = [];
    m.redraw();

    const result = await context.auth.login(formData.username, formData.password);

    if (result.body.status === 201) {
      setPath('/');
      return;
    }

    state.loading = false;
    errors.push('Incorrect login details');
    m.redraw();
  }

  return {
    view: () => {
      return (
        <div class='wrapper with-padding'>
          <b-auth-box>
            <img src={require('../../images/logo.svg')} />

            <div>
              <p>Welcome to Bumbana Collaboration.</p>
              <p>We're trying to connect you to our servers.</p>
              <div class='loading'>
                <img src={require('../../images/loading.svg')} />
              </div>
            </div>

            <div />

          </b-auth-box>
        </div>
      );
    }
  };
}

NotConnected.layout = 'none';

module.exports = NotConnected;
