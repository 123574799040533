const hazkeyboard = require('hazkeyboard');
const m = require('../mithril');

const removeAfterAnimations = require('../app/removeAfterAnimations');

const Header = require('../components/Header');
const Footer = require('../components/Footer');

const DefaultLayout = function () {
  const redraw = () => m.redraw();

  return {
    oncreate: async () => {
      document.addEventListener('focusin', redraw);
      document.addEventListener('focusout', redraw);
    },

    onremove: () => {
      document.removeEventListener('focusin', redraw);
      document.removeEventListener('focusout', redraw);
    },

    view: (vnode) => {
      if (hazkeyboard.visible || vnode.attrs.hideMenu) {
        return (
          <div key='wrapper' class='wrapper page-transition'>
            <Header key='header' context={vnode.attrs.context} />
            <div class='pageTransitionContainer' key={document.location.href} onbeforeremove={removeAfterAnimations}>
              {vnode.children}
            </div>
          </div>
        );
      }

      return (
        <div key='wrapper' class='wrapper page-transition'>
          <Header key='header' context={vnode.attrs.context} />
          <div class='pageTransitionContainer' key={document.location.href} onbeforeremove={removeAfterAnimations}>
            {vnode.children}
          </div>
          <Footer key='footer' context={vnode.attrs.context} active={vnode.attrs.active} />
        </div>
      );
    }
  };
};

module.exports = DefaultLayout;
