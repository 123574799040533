
const { setPath } = require('spath');

const ChatList = require('../components/ChatList');
const goBack = require('../app/goBack');

const m = require('../mithril');

const handleChatAutoScroll = require('../app/handleChatAutoScroll');
const subscriptionToState = require('../app/subscriptionToState');

function ContactMessagesHome (vnode) {
  const { context, tokens } = vnode.attrs;

  const state = {
    autoScrollToLast: true
  };

  async function onGrabMoreMessages (cb) {
    const createdBefore = state.messages[0].createdTime;
    const result = await context.api.get(`/contacts/${tokens.username}/messages?createdBefore=${createdBefore}`);

    state.messages = result.body.messages.concat(state.messages);

    cb && cb();
  }

  function onCreateMessage (formData) {
    return context.api.post(`/contacts/${tokens.username}/messages`, formData);
  }

  return {

    oncreate: async () => {
      state.subscriptions = subscriptionToState(context, {
        state,
        mappings: {
          messages: `/contacts/${tokens.username}/messages`
        },
        autoRedraw: false,
        onMessage: handleChatAutoScroll.bind(null, state),
        customCacheLogic: function (idbCache, path, state, key) {
          idbCache.set(path, state[key].slice(-100));
        }
      });
    },

    onremove: async () => {
      state.subscriptions.close();
    },

    view: () => {
      if (!context.session) {
        setPath('/login');
        return;
      }

      return (
        <div>
          <div class='heading'>
            <a tabindex='0' class='back-button' onclick={goBack('/contacts', 1)}>‹ Back</a>
            <div class='title'>Contacts › {tokens.username}</div>
          </div>
          <ChatList {...{ context, state, tokens, onCreateMessage, onGrabMoreMessages }} />
        </div>
      );
    }
  };
}

ContactMessagesHome.activeMenu = 'contacts';
ContactMessagesHome.hideMenu = true;

module.exports = ContactMessagesHome;
