/** @jsx m */
const m = require('../mithril');

function Header () {
  return {
    view: (vnode) => {
      const { context } = vnode.attrs;

      return (
        <header>
          <div class='logo' />

          {context.session &&
            <b-user-icon tabindex='0'>
              <div>
                {context.session.user.username}
                <img src={require('../../images/no-avatar.svg')} />
              </div>

              <b-user-menu>
                <ul>
                  <li><span>My Account</span></li>
                  <li><span>Change Password</span></li>
                  <li><a onclick={context.auth.logout}>Logout</a></li>
                </ul>
              </b-user-menu>
            </b-user-icon>}
        </header>
      );
    }
  };
}

module.exports = Header;
