
const { setPath } = require('spath');

const FieldError = require('../components/FieldError');
const goBack = require('../app/goBack');

const m = require('../mithril');

function CreateCommunity () {
  const formData = {
    invitationType: 'private'
  };
  let errors = [];

  function handleInput (name) {
    return event => {
      formData[name] = event.target.value;
    };
  }

  async function handleSubmit (context, formData, event) {
    event.preventDefault();

    errors = { summary: [], fields: {} };
    m.redraw();

    const result = await context.api.post('/communities', formData);

    if (result.body.status === 201) {
      goBack('/', 1)();
      return;
    }

    if (result.body.status === 422) {
      errors.fields = result.body.error.validation;
    }

    m.redraw();
  }

  return {

    view: (vnode) => {
      const { context } = vnode.attrs;

      if (!context.session) {
        setPath('/login');
        return;
      }

      return (
        <div>
          <div class='heading'>
            <a tabindex='0' class='back-button' onclick={goBack('/', 1)}>‹ Back</a>
            <div class='title'>Create your community</div>
          </div>

          <main class='form-page'>
            <form onsubmit={handleSubmit.bind(null, context, formData)}>
              <b-form-field>
                <label>Community Name</label>
                <FieldError errors={errors} errorKey='name' customLanguage={{ INVALID_LENGTH: 'must be between 3 and 20 in length' }} />
                <input type='text' autocapitalize='off' oninput={handleInput('name')} required autofocus />
              </b-form-field>

              <b-form-field>
                <label>Visibility</label>
              </b-form-field>
              <b-form-field>
                <b-form-radio>
                  <input type='radio' id='typePrivate' name='invitationType' value='private' oninput={handleInput('invitationType')} checked={formData.invitationType === 'private'} />
                  <label for='typePrivate'>Private</label>
                </b-form-radio>
                <div>
                  Only you and members you invite will be able to view this community.
                </div>
              </b-form-field>
              <b-form-field>
                <b-form-radio>
                  <input type='radio' id='typePublic' name='invitationType' value='public' oninput={handleInput('invitationType')} checked={formData.invitationType === 'public'} />
                  <label for='typePublic'>Public</label>
                </b-form-radio>
                <div>
                  Only you and members you invite will be able to view this community.
                </div>
              </b-form-field>

              <b-form-field>
                <button class='button-primary'>Create</button>
              </b-form-field>
            </form>
          </main>
        </div>
      );
    }
  };
}

CreateCommunity.activeMenu = 'community';

module.exports = CreateCommunity;
