
const { setPath } = require('spath');
const debounce = require('debounce-promise');

const goBack = require('../app/goBack');

const colorhash = require('../../../modules/colorhash');

const m = require('../mithril');
const colors = [
  'hsl(240deg 125% 30%)',
  'hsl(220deg 125% 30%)',
  'hsl(200deg 125% 30%)',
  'hsl(180deg 125% 30%)',
  'hsl(160deg 125% 30%)',
  'hsl(140deg 125% 30%)',
  'hsl(120deg 125% 30%)'
];

function SearchCommunities (vnode) {
  const { context } = vnode.attrs;

  const state = {
  };

  const formData = {
    name: ''
  };

  const refreshResults = debounce(async function () {
    state.loading = true;
    m.redraw();

    const joinedCommunities = await context.api.get('/communities?joined=true');
    state.joinedCommunities = joinedCommunities.body.communities;

    const communities = await context.api.get('/communities?name=' + formData.name);
    state.searchResults = communities.body.communities;

    state.loading = false;
    m.redraw();
  }, 250);

  function handleInput (event) {
    formData.name = event.target.value;
    refreshResults();
  }

  async function handleJoin (context, communityName, event) {
    event.preventDefault();
    event.stopPropagation();

    const result = await context.api.post(`/communities/${communityName}/members`, {
      username: context.session.user.username
    });

    if (result.body.status === 201) {
      refreshResults();
      return;
    }

    m.redraw();
  }

  return {

    oncreate: async () => {
      refreshResults();
    },

    view: (vnode) => {
      const { context } = vnode.attrs;

      if (!context.session) {
        setPath('/login');
        return;
      }

      return (
        <div>
          <div class='heading'>
            <a tabindex='0' class='back-button' onclick={goBack('/', 1)}>‹ Back</a>
            <div class='title'>Search communities</div>
          </div>

          <main class='listview'>
            <div class='search-field'>
              <input autofocus oninput={handleInput} placeholder='Search by community name' />
            </div>

            {state.loading && (
              <div class='loading'>
                <img src={require('../../images/loading.svg')} />
              </div>
            )}

            {state.searchResults && state.searchResults.length === 0 && (
              <div>
                <div class='listview-item-main'>No communities found</div>
              </div>
            )}

            {state.searchResults && state.searchResults.map(community => {
              const joined = state.joinedCommunities && state.joinedCommunities.find(joinedCommunity => community.name === joinedCommunity.name);

              return (
                <div onclick={() => joined && setPath('/communities/' + community.name)} tabindex='0' key={community.name}>
                  <div>
                    <div
                      class='wordatar' style={{
                        backgroundColor: colorhash(colors, community.name)
                      }}
                    >
                      <span>{community.name[0]}</span>
                    </div>
                  </div>
                  <div class='listview-item-main'>
                    <strong>{community.name}</strong>
                    <div>1 member</div>
                  </div>
                  <div>
                    {!joined && <button class='large-button' onclick={handleJoin.bind(null, context, community.name)}>Join</button>}
                    {joined && <button class='large-button' disabled>Joined</button>}
                  </div>
                </div>
              );
            })}
          </main>
        </div>
      );
    }
  };
}

SearchCommunities.activeMenu = 'community';

module.exports = SearchCommunities;
