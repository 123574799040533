function syncArray (state, key) {
  return (event) => {
    if (!event.body.id) {
      throw new Error('syncArray - event body did not have an id');
    }

    if (event.method === 'POST') {
      state[key].push(event.body);
    }
    if (event.method === 'PUT') {
      const existingItem = state[key].find(item => item.id === event.body.id);
      if (existingItem) {
        Object.assign(existingItem, event.body);
      } else {
        state[key].push(event.body);
      }
    }
    if (event.method === 'DELETE') {
      const existingItemIndex = state[key].findIndex(item => item.id === event.body.id);
      if (existingItemIndex > -1) {
        state[key].splice(existingItemIndex, 1);
      }
    }
  };
}

function syncObject (state, key) {
  return (event) => {
    state[key] = event.body;
  };
}

module.exports = {
  syncArray,
  syncObject
};
